<template>
	<p class="mb-0">
		<template v-if="order && Object.keys(order).length > 0"> 
            <template v-if="order.payment_status_id == 1 && order.payment_payload && Object.keys(order.payment_payload).length > 0 && ('url' in order.payment_payload)">
    			<a :href="`${order.payment_payload.url}${order.payment_payload.code}`"				
    				target="_blank"				
    				class="btn-hover btn-order-payment">	            
    	            <p>{{ $t('my_orders_intern.click_to_go_payment') }}</p>
    	        </a>
    		</template>
			<!-- (!order.payment_payload || Object.keys(order.payment_payload).length == 0) -->
    		<template v-else-if="order.payment_status_id == 1 && order.payment_status.slug == 'waiting-payment' ">
    			<a @click="payment" href="javascript:;" class="btn-hover btn-order-payment">
    	            <img :src="order.payment_type.logo_path" class="img-fluid" style="height: 50px;">
    	            <p>{{ $t('my_orders_intern.click_to_pay') }}</p>
    	        </a>
    		</template>
			<template v-else-if="order.payment_status.slug == 'expired'">
    			<a @click="payment" href="javascript:;" class="btn-hover btn-order-payment">
    	            <img :src="order.payment_type.logo_path" class="img-fluid" style="height: 50px;">
    	            <p>{{ $t('my_orders_intern.click_to_pay') }}</p>
    	        </a>
    		</template>
        </template>
    </p>
</template>
<script>    
    import {mapGetters, mapActions} from 'vuex';

    export default{       
        computed: {
            ...mapGetters('Order', ['order', 'loading']),
        },
        methods: {
            ...mapActions('Order', ['methodPayment']), 
            payment(){
            	let params = {};
            	params.id = this.order.id;
            	params.redirect = window.location.href;
            	params.host = window.location.host;

            	this.methodPayment(params).then(response => {
            		this[response.method](response);
            	});
            },
            pagseguro(response){            	
            	window.location.href = response.url+""+response.code;
            }
        }
    }
</script>
<style lang="scss" scoped>
.btn-order-payment {
	display: block;
	text-decoration: none;
	margin-top: 20px;
	transition: 500ms;
	&:hover,&:focus {
		cursor: pointer;
		opacity: 0.8;
	}
	p {
		font-size: 16px;
    font-family: Font Bold;
    color: var(--main-color4);
		margin-bottom: 0;
	}
}
</style>