<template>
  <div class="col-12">
    <div class="content-request-products">
      <div class="content-table">
        <table>
          <thead>
            <tr>
              <th>{{ $t('my_orders_intern.product') }}</th>
              <th>IVA</th>
              <th>{{ $t('my_orders_intern.price_unit') }}.</th>
              <th>QTDE.</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in order.itens" :key="item.id">
              <td class="content-table-product">
                <img
                  class="img-fluid"
                  :src="item.product.attachs[0]"
                  alt="image product"
                  title="image product"
                />
                <p class="content-table-product-name">
                  {{ item.product.name }}
                </p>
              </td>
              <td>23%</td>
              <td>{{ item.price_unit | money }}</td>
              <td>{{ item.amount }}x</td>
              <td>{{ item.price_total | money }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Order", ["order", "loading"]),
  },
};
</script>