<template>
  <div class="col-12">
    <div class="content-request-summary">
      <div class="content-request-summary-header">
        <h2>{{ $t('my_orders_intern.summary') }}</h2>
      </div>
      <div class="content-request-summary-body">
        <div class="elements">
          <p>{{ $t('my_orders_intern.total_items') }}</p>
          <p>{{ order.price_itens|money }}</p>
        </div>
        <div class="elements">
          <p>{{ $t('my_orders_intern.shipping') }}</p>
          <p>{{ order.price_delivery|money }}</p>
        </div>
        <div class="elements">
          <p>{{ $t('my_orders_intern.total_order') }}</p>
          <p>{{ order.price_total|money }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Order", ["order", "loading"]),
  },
};
</script>