<template>
  <div class="col-lg-6">
    <div v-if="loading">
      <PuSkeleton style="height: 120px; width: 100%" />
    </div>
    <div v-else-if="Object.keys(order).length > 0" class="content-request-data" :class="{'data-take_away': order.payment_type.slug == 'deposito'}">
      <div class="content-request-data-header">
        <h2>{{ $t('my_orders_intern.data_requested') }}</h2>
      </div>
      <div class="content-request-data-texts">
        <div class="elements">
          <p>{{ $t('my_orders_intern.date_purchase') }}</p>
          <p>{{ order.created_at | moment("DD/MM/YYYY HH:mm") }}</p>
        </div>
        <div class="elements">
          <p>{{ $t('my_orders_intern.tracking_code') }}</p>
          <p v-if="order.delivery_code_track">{{ order.delivery_code_track }}</p>
          <p v-else>---</p>
        </div>
        <div class="elements">
          <p>{{ $t('my_orders_intern.situation_payment') }}</p>
          <p>
            <span
              class="elements-situation"
              :style="{ background: order.payment_status.color }"
            >
              {{ order.payment_status.name }}
            </span>
          </p>
        </div>
        <div class="elements">
          <p>{{ $t('my_orders_intern.situation_order') }}</p>
          <p>
            <span
              class="elements-situation"
              :style="{ background: order.status.color }"
            >
              {{ order.status.name }}
            </span>
          </p>
        </div>
      </div>
      <div class="content-request-data-payment">
        <div class="elements">
          <p>{{ $t('my_orders_intern.method_payment') }}</p>
          <p>{{ order.payment_type.name }}</p>
        </div>
        <template v-if="order.payment_type.has_integration">
          <!--  -->
          <method-payments></method-payments>
          <!--  -->
        </template>
        <template v-else-if="order.payment_type.configurations.banks.length > 0">
          <div class="elements elements-trf" v-for="(item, itemIndex) in order.payment_type.configurations.banks" :key="itemIndex">
            <p>{{ item.name }}</p>
            <ul>
              <li v-for="value in item.data" :key="value.field">
                {{ value.field }}: <span>{{ value.value }}</span>
              </li>
            </ul>
          </div>
          <!-- <div v-for="(item, itemIndex) in order.payment_type.configurations.banks" :key="itemIndex">
            <div align="center">
              <h5>
                <b>{{ item.name }}</b>
              </h5>
            </div>
            <ul>
              <li v-for="value in item.data" :key="value.field">
                {{ value.field }} <span>{{ value.value }}</span>
              </li>
            </ul>
          </div> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import MethodPayments from "./MethodPayments";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    MethodPayments,
  },
  computed: {
    ...mapGetters("Order", ["order", "loading"]),
  },
  methods: {
    ...mapActions("Order", ["methodPayment"]),
  },
};
</script>
<style lang="scss" scoped>
.elements-trf {
  flex-direction: column;
  align-items: flex-start !important;
}
</style>
