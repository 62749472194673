<template>
  <section class="content-my-orders-details">
    <div class="content-my-orders-details-itens container">
      <div class="content-my-orders-details-itens-body">
        <div class="content-title">
          <h2>Encomenda nº {{ order.id }}</h2>
        </div>
        <div class="content-request">
          <div class="row">
            <info-order />
            <info-delivery />
          </div>
          <div class="row">
           <products />
          </div>
          <div class="row">
            <sumary />
          </div>
          <div class="row">
            <div class="col-12">
              <router-link
                :to="{ name: 'MyOrders' }"
                class="btn-continue-shopping"
              >
                <img
                  class="img-fluid"
                  src="@/assets/img/arrow-left.png"
                  alt="arrow left"
                  title="arrow left"
                />
                {{ $t('btns_default.my_orders') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InfoOrder from "./InfoOrder";
import InfoDelivery from "./InfoDelivery";
import Products from "./Products";
import Sumary from "./Sumary";
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo: {
    title: "Minha encomenda",
  },
  components: {
    InfoDelivery,		
    InfoOrder,
    Products,
    Sumary,
	},
  computed: {
    ...mapGetters("Order", ["order", "loading"]),
  },
  methods: {
    ...mapActions("Order", ["getOrder"]),
  },
  mounted() {
    let params = {};
    params.id = this.$route.params.id;

    this.getOrder(params);
  },
};
</script>

<style lang="scss">
.content-my-orders-details {
  padding: 80px 0px;
  @media (max-width: 991px) {
    padding: 40px 0px;
  }
  &-itens {
    &-body {
      .content-title {
        margin-bottom: 30px;
        text-align: left;
        h2 {
          font-size: 30px;
          font-family: "Font Bold";
          color: var(--main-color5);
          margin-bottom: 0;
          @media (max-width: 576px) {
            font-size: 28px;
          }
        }
      }
      .content-request {
        &-data {
          padding: 20px;
          width: 100%;
          background: #f8f8f8;
          border: 1px solid #ebebeb;
          @media (min-width: 992px){
            height: 396px;
          }
          @media (max-width: 991px) {
            margin-bottom: 30px;
          }
          &-header {
            border-bottom: 1px solid #ebebeb;
            text-align: left;
            padding: 5px 0px;
            h2 {
              font-size: 20px;
              font-family: "Font Bold";
              color: var(--main-color4);
              margin-bottom: 0;
              text-transform: uppercase;
            }
          }
          &-texts {
            text-align: left;
            padding: 10px 0px;
            .elements {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 0px;
              p {
                font-size: 16px;
                font-family: "Font Light";
                color: #787878;
                margin-bottom: 0;
                @media (max-width: 576px) {
                  font-size: 14px;
                }
                &:last-child {
                  font-family: "Font Bold";
                  color: #000;
                }
              }
              &-situation {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 5px;
                font-size: 14px;
                font-family: "Font Light";
                color: var(--main-color);
                border-radius: 5px;
                @media (max-width: 576px){
                  font-size: 10px;
                }
              }
              .wait {
                background: #00c5ff;
              }
              .sucess {
                background: var(--main-color5);
              }
              input {
                border: 0;
                background: 0;
                display: block;
                padding: 0;
                text-align: right;
                font-family: Font Bold;
                color: #000;
                font-size: 16px;
              }
            }
          }
          &-payment {
            padding: 10px 0px;
            border-top: 1px solid #ebebeb;
            .elements {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 0px;
              p {
                font-size: 16px;
                font-family: "Font Light";
                color: #787878;
                margin-bottom: 0;
                @media (max-width: 576px) {
                  font-size: 14px;
                }
                &:last-child {
                  font-family: "Font Bold";
                  color: #000;
                }
              }
              ul {
                padding: 0;
                list-style: none;
                margin-bottom: 0;
                li {
                  font-size: 16px;
                  font-family: "Font Light";
                  color: #787878;
                  margin-bottom: 0;
                  @media (max-width: 576px) {
                    font-size: 14px;
                  }
                  span {
                    font-family: "Font Bold";
                    color: #000;
                  }
                }
              }
            }
          }
        }
        &-products {
          margin: 50px 0px;
          @media (max-width: 991px) {
            margin: 0;
            margin-bottom: 30px;
          }
          .content-table {
            width: 100%;
            border: 1px solid #ebebeb;
            @media (max-width: 767px) {
              overflow-x: scroll;
              -webkit-overflow-scrolling: touch;
            }
            table {
              width: 100%;
              @media (max-width: 767px) {
                width: 800px;
              }
              thead {
                background: #f8f8f8;
                tr {
                  th {
                    font-size: 18px;
                    font-family: "Font Bold";
                    color: var(--main-color4);
                    padding: 20px;
                    text-align: left;
                    text-transform: uppercase;
                  }
                }
              }
              tbody {
                tr {
                  border-top: 1px solid #ebebeb;
                  td {
                    font-size: 18px;
                    font-family: "Font Light";
                    color: #787878;
                    padding: 20px;
                    text-align: left;
                  }
                }
              }
            }
            &-product {
              display: flex;
              align-items: center;
              img {
                max-width: 100px;
              }
              &-name {
                margin-left: 20px;
              }
            }
          }
        }
        &-summary {
          padding: 20px;
          width: 100%;
          background: #f8f8f8;
          border: 1px solid #ebebeb;
          margin-bottom: 50px;
          &-header {
            border-bottom: 1px solid #ebebeb;
            text-align: left;
            padding: 5px 0px;
            h2 {
              font-size: 20px;
              font-family: "Font Bold";
              color: var(--main-color4);
              margin-bottom: 0;
              text-transform: uppercase;
            }
          }
          &-body {
            padding: 10px 0px;
            .elements {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 5px 0px;
              p {
                font-size: 16px;
                font-family: "Font Light";
                color: #787878;
                margin-bottom: 0;
                &:last-child {
                  font-family: "Font Bold";
                  color: #000;
                  font-size: 20px;
                }
              }
            }
          }
        }
        .btn-continue-shopping {
          display: flex;
          align-items: center;
          background: 0;
          border: 0;
          font-size: 16px;
          font-family: "Font Bold";
          color: var(--main-color4);
          padding: 0;
          text-decoration: none;
          transition: 500ms;
          &:hover,
          &:focus {
            cursor: pointer;
            opacity: 0.8;
          }
          img {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
</style>