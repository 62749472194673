<template>
  <div class="col-lg-6">
    <div v-if="loading">
      <PuSkeleton style="height: 120px; width: 100%" />
    </div>
    <div v-else-if="Object.keys(order).length > 0" class="content-request-data" :class="{'data-take_away': order.payment_type.slug == 'deposito'}">
      <div class="content-request-data-header">
        <h2>{{ $t('my_orders_intern.data_delivery_collection') }}</h2>
      </div>
      <div class="content-request-data-texts">
        <template v-if="order.type_delivery == 'take_away'">
          <div class="elements">
            <p>{{ $t('my_orders_intern.type_delivery') }}</p>
            <p>Levantar na Loja</p>
          </div>  
          <div class="elements">
            <p>Loja</p>
            <p>{{ order.unit.name }}</p>
          </div>  
          <div class="elements">
            <p>Endereço</p>
            <p>{{ order.unit.address }}</p>
          </div> 
          <div class="elements">
            <p>Código Postal</p>
            <p>{{ order.unit.postal_code }}</p>
          </div> 
          <div class="elements">
            <p>Cidade</p>
            <p>{{ order.unit.city.name }}</p>
          </div> 
          <!-- <div class="elements">
            <p>Estado</p>
            <p>{{ order.unit.state.name }}</p>
          </div>  -->
          <div class="elements" v-if="order.unit.phone !== ''">
            <p>Telefone</p>
            <p>{{ order.unit.phone }}</p>
          </div> 
          <div class="elements" v-if="order.unit.phone_whatsapp !== ''">
            <p>Whatsapp</p>
            <p>{{ order.unit.phone_whatsapp }}</p>
          </div>
        </template>
        <template v-else>
          <div class="elements">
            <p>{{ $t('my_orders_intern.type_delivery') }}</p>
            <p>{{ order.type_delivery_name }}</p>
          </div>
          <div class="elements">
            <p>{{ $t('my_orders_intern.price_delivery') }}</p>
            <p>{{ order.price_delivery | money }}</p>
          </div>
          <div class="elements">
            <p>{{ $t('my_orders_intern.code_postal') }}</p>
            <the-mask :mask="['####-###']" :masked="true" :value="order.postal_code" disabled />
          </div>
          <div class="elements">
            <p>{{ $t('my_orders_intern.address') }}</p>
            <p>{{ order.address }} Nº {{ order.address_number }}</p>
          </div>
          <div class="elements">
            <p>{{ $t('my_orders_intern.reference') }}</p>
            <p>{{ order.address_reference }}</p>
          </div>
          <div class="elements">
            <p>{{ $t('my_orders_intern.complement') }}</p>
            <p>{{ order.address_complement }}</p>
          </div>
          <div class="elements">
            <p>{{ order.state.name }}</p>
            <p>{{ order.city.name }}</p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { TheMask } from "vue-the-mask";

export default {
  components: {
    TheMask
  },
  computed: {
    ...mapGetters("Order", ["order", "loading"]),
  },
};
</script>

<style lang="scss">
@media (min-width: 992px){
  .data-take_away {
    height: 361px !important;
  }
}
</style>